@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

@mixin productItem {
  margin-bottom: 12px;
  box-shadow: 0 4px 16px 0 #0D2B9614;
  border-radius: $borderRadius20;
  padding: 24px 8px;

  .code-wrapper {
    width: 100%;
    display: flex;
    justify-content: end;

    .code {
      background: $colorBackgroundSurfaceSecondary;
      padding: 6px 16px;
      width: max-content;
      border-radius: 100px;
      @include standardNarrow;
      color: $colorTextSecondary;
    }
  }

  .image-wrapper {
    text-align: center;
    margin: 16px 24px 63px;
    height: 194px;

    img {
      margin: 0 auto;
      object-fit: contain;
      height: 100%;
      border-radius: 20px;
      width: max-content;
    }
  }

  .bottom-wrapper {
    padding: 0 24px;

    .info-wrapper {
      position: relative;
      padding-bottom: 24px;

      .title {
        @include subtitleSize;
        color: $colorTextPrimary;
        font-family: "NotoSansArmenian-SemiBold", sans-serif;
        margin-bottom: 12px;
      }

      .description {
        @include subtitleSize;
        color: $colorTextSecondary;
      }

      &:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        background: $colorConstantGrey100;
        bottom: 0;
        left: 0;
      }

    }

    .point-logo {
      width: 100%;
      justify-content: space-between;
      padding: 16px 0;
      align-items: center;

      > div {
        gap: 10px;
        align-items: center;

        .point-value {
          color: $colorTextLinkDefault;
          @include sectionMediumTitleSize;
          font-family: "NotoSansArmenian-SemiBold", sans-serif;
        }

        .point-label {
          @include standardNarrow;
          color: $colorTextSecondary;
        }
      }
    }
  }
}

@mixin slider {
  .slick-slider {
    .slick-slide {
      padding: 0 16px;

      .product-item {
        @include productItem;
      }
    }

    .slick-dots {
      bottom: -40px;
      gap: 8px;
      z-index: 1111;

      li {
        width: max-content;

        button {
          &:before {
            transition: 0.3s ease-out;
            font-size: 10px;
          }

          &:hover {
            &:before {
              opacity: .75;
            }
          }
        }
      }
    }

    .slick-arrow {
      box-shadow: 0 4px 16px 0 #0D2B9614;
      border: 1px solid $colorBackgroundSurfaceBorder;
      width: 48px;
      height: 48px;
      border-radius: 12px;
      top: 47%;
      background: white;
      z-index: 111;
      &.slick-next {
        right: 60px;
      }

      &.slick-prev {
        left: 60px;
      }
    }
  }
}

@mixin select {
  margin-bottom: 0;

  .select__menu {
    box-shadow: 0 4px 16px 0 #0D2B9614;
    border-radius: $borderRadius12;

    .select__menu-list {
      .select__option {
        @include standardNarrow;
        color: $colorTextPrimary;
        padding: 8px;
      }
    }
  }

  .select__control {
    border-radius: $borderRadius12;
    color: $colorTextPrimary;
    height: 48px;

    .select__placeholder {
      color: $colorTextPrimary;
    }

    &.select__control--menu-is-open {
      border: 1px solid $colorControlBorderFocus;
    }
  }
}

@mixin date {
  padding: 2px 12px;
  @include standardNarrow;
  color: $colorTextSecondary;
  margin-bottom: 20px;
  background: white;
  border-radius: 100px;
  width: max-content;

}


.benefits-section {
  .banner-wrapper .banner-info-wrapper .banner-title {
    @include titleSize52;
    margin-bottom: 24px;
    font-family: "NotoSansArmenian-SemiBold", sans-serif;
  }

}

.chakra-tabs__tablist {
  justify-content: start !important;

  .tab-buttons {
    gap: 16px !important;
  }
}

.benefits-main-page-section {
  .issued-cards-wrapper {
    background: $colorConstantGrey50;
    padding: 56px 0;

    .header-wrapper {
      text-align: center;
      margin-bottom: 32px;

      .title {
        @include sectionMediumTitleSize;
        color: $colorTextPrimary;
        font-family: "NotoSansArmenian-SemiBold", sans-serif;
        margin-bottom: 24px;
      }

      .text {
        color: $colorTextSecondary;
        @include mediumText;
      }
    }

    .issued-cards {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      gap: 32px;

      .card {
        background: white;
        border-radius: 24px;
        padding: 24px;
        width: calc(33% - 18px);

        img{
          width: 24px;
          height: 24px;
        }

        .title-wrapper {
          gap: 16px;
          flex-direction: column;
          height: 100%;
          .card-title {
            color: $colorTextPrimary;
            @include mediumText;
            font-family: "NotoSansArmenian-SemiBold", sans-serif;
          }
        }

        .card-description {
          margin-top: 16px;
          @include standardNarrow;
          color: $colorTextSecondary;
        }


        &.big:nth-last-child(odd) {
          grid-area: 1 / 3 / 3 / 4;
        }
      ;
      }
    }
    .issued-cards-odd{
      gap: 32px;
      .issued-cards{
        width: calc(66.6% - 16px);
        .card{
          width: calc(50% - 16px);
        }
      }
      .issued-cards.odd{
        width: calc(33.3% - 16px);
        .card{
          width: 100%!important;
        }
      }
      @media (max-width: 991px) {
        flex-direction: column;
        .card{
          width: 100%!important;
        }
      }
    }
    @media (max-width: 991px) {
      .issued-cards{
        flex-direction: column;
        width: 100%!important;
        .card{
          width: 100%!important;
        }
        &.odd{
          width: 100%!important;
        }
      }
    }
  }

  .gifts-slider-wrapper {
    padding-top: 80px;

    .header-wrapper {
      width: 100%;
      justify-content: space-between;
      align-items: center;
      a{
        width: max-content!important;
      }
      .section_title {
        margin-bottom: 0;
      }
      @media (max-width: 991px) {
        flex-direction: column;
        justify-content: start;
        align-items: start;
        a{
          padding-left: 0;
        }

      }
    }

    .slider-wrapper {
      margin-top: 32px;
      padding-bottom: 80px;

      @include slider;
      .slick-slider .slick-slide{
        max-width: 400px;
      }
    }
  }
  .faq-wrapper{
    margin: 0 0 80px 0;
  }
}

.benefits-gifts-section {
  padding-bottom: 80px;

  .gifts-wrapper {
    .gifts {
      gap: 32px;
      flex-wrap: wrap;

      .gift {
        width: calc(33.3% - 21px);
        box-shadow: 0 4px 16px 0 #0D2B9614;
        padding: 24px 24px 0;
        border-radius: $borderRadius20;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        transition: 0.3s ease-out;

        .title {
          @include titleSize20;
          margin-bottom: 12px;
          font-family: "NotoSansArmenian-SemiBold", sans-serif;
          color: $colorTextPrimary;
        }

        .text {
          @include standardText;
          color: $colorTextSecondary;
          margin-bottom: 24px;
        }

        .img-wrapper {
          align-items: end;
          justify-content: center;

          img {
            object-fit: cover;
            height: 140px;
            margin: 0 auto;
          }
        }

        &:last-child, &:nth-last-child(-n+2) {
          width: calc(50% - 16px);
        }

        &.last {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 200px;
        }
        @media (max-width: 991px) {
          width: 100%!important;
          &.last {
            padding-top:0;
            min-height: 200px;
          }
        }
        &:hover{
          box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.19);
        }
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          width: calc(33.3% - 21px);
        }
      }
    }
  }
}

.products-tab-buttons{
  gap: 16px;
  a  {
    border: none;
    border-radius: 12px;
    color: #677482;
    font-size: 14px;
    line-height: 1.5em;
    padding: 8px 12px;
    transition: 0.3s ease-out;
    background: #f0f0f0;
    &.active,&:hover{
      background: #e6eaf6;
      color: #070b11;
    }
  }
}

.benefits-product-list-section {
  margin-top: 20px;


  .back {
    display: flex;
    align-items: center;
    color: $colorTextSecondary;
    @include mediumText;
    margin-bottom: 32px;
    gap: 4px;
    width: max-content;
  }

  .filter-section {
    button {
      gap: 12px;
      align-items: center;
      justify-content: space-between;
      padding: 6px 12px;
      display: none;
      @media (max-width: 991px) {
        width: max-content !important;
        margin-left: auto;
        display: flex;
      }
    }
    .buttons-wrapper{
      display: none;
      @media (max-width: 991px) {
        display: flex;
        button {
          padding: 6px 12px;
          width: 100% !important;
          margin-left: unset !important;
          display: flex;
          justify-content: center;

        }
      }
    }
    .product-filter-wrapper {
      margin: 8px 0 32px;

      .field_block {
        @include select;
      }
      label.points-inputs{
        color: $colorTextSecondary;
        @include standardNarrow;
        padding: 0 4px;
        justify-content: space-between;
        flex-direction: column;
        display: flex;

        input{
          height: 48px;
          margin-top: 5px;
          border-radius: 10px;
          padding: 2px 8px;
          border: 1px solid #ccc;
          @include mediumText;
          color: $colorTextPrimary;
          max-width: 150px;
          &::placeholder{
            @include mediumText;
            color: $colorTextPrimary;
          }
          @media (max-width: 991px) {
            max-width: unset;
            width: 100%;
          }
        }
      }
    }

    @media (max-width: 991px) {
      margin-bottom: 24px;
      .popup_body{
        margin: 0;
      }
      .product-filter-wrapper {

        gap: 16px;

        > div {
          flex-wrap: wrap;

          &:first-child {
            width: 100%;

            .field_block {
              width: 100%;
            }
          }

          &:last-child {
            width: 100%;
            margin-top: 20px;
            .field_block {
              width: 100%;
            }
          }
        }

      }
      .buttons-wrapper{
        justify-content: space-between;
        gap: 24px;
      }
    }
    @media (max-width: 768px) {

      .product-filter-wrapper {
        flex-direction: column;
        gap: 16px;

        > div {
          flex-direction: column;
          width: 100% !important;
          .field_block {
            width: 100% !important;
          }
        }
      }
    }
    .buttons-wrapper{
      flex-direction: column;
      gap: 24px;
    }
  }


  .products-wrapper {
    gap: 32px;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 32px;

    .product-item {
      @include productItem;
      cursor: pointer;
      transition: 0.3s ease-out;
      &:hover{
        box-shadow: 0 0 16px 0 #2B48AE14;
      }
      width: calc(33.3% - 21px);
      @media (max-width: 991px) {
        width: calc(50% - 21px);
      }
      @media (max-width: 678px) {
        width: 100%;
      }
    }
  }

  .product-view-modal {
    .popup_container {
      max-width: 900px;
      .popup_close{
        right: 10px;
        top: 10px;
      }
      .product-item {
        @include productItem;
        box-shadow: none;
        padding: 0 0 4px;

        .left-wrapper {
          width: 40%;
          flex-direction: column;
          justify-content: space-between;
          @include slider;

          .image-wrapper {
            margin: 0;

            img {
              height: 194px;
            }
          }

          .slick-arrow.slick-next {
            right: 16px !important;
          }

          .slick-arrow.slick-prev {
            left: 0 !important;
          }

          .slick-dots {
            bottom: -70px !important;
          }
        }

        .bottom-wrapper {
          padding: 32px 32px 0;
          width: 60%;

          .info-wrapper {
            flex-direction: column;
            justify-content: space-between;
            gap: 20px;

            .description, .code-body {
              @include standardNarrow
            }

            .title {
              @include sectionMediumTitleSize
            }

            .code-body {
              color: $colorTextSecondary;
            }
          }

          .location {
            gap: 8px;
            margin-bottom: 16px;
            align-items: center;
            color: $colorTextSecondary;
            @include standardNarrow;
            a{
              color: $colorTextSecondary;
              @include standardNarrow;
            }
          }
        }

      }
      @media (max-width: 991px) {
        width: 100%!important;

        .product-item{
          flex-direction: column;
          >div{
            width: 100%!important;
          }
          .left-wrapper{
            padding-bottom: 50px;
          }
        }
      }
    }
  }
}


.benefits-discount-section {
  margin-bottom: 80px;

  .table-wrapper {
    .top-wrapper {
      justify-content: space-between;
      align-items: center;
      margin-bottom: 32px;

      .section_title {
        margin-bottom: 0;
      }

      .field_block {
        @include select;
      }
    }

    table {
      @include tableDesign;

      a {
        color: $colorTextLinkDefault;
        text-decoration: underline;
        @include mediumText
      }

      img {
        margin-left: 12px;
      }

    }
    @media (max-width: 750px) {
      overflow-x: scroll;
      padding-bottom: 30px;
      &::-webkit-scrollbar {
        width: 1em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $fieldBorder;
        border-radius: 6px;
      }
    }
  }
}


.benefits-promotions-section {
  margin-bottom: 80px;

  .promotions-wrapper {
    .promotions {
      display: flex;
      flex-direction: column;
      gap: 32px;

      .promotion {
        padding: 64px;
        background: $colorBackgroundSurfaceSecondary;
        border-radius: $borderRadius20;

        .info-wrapper {
          max-width: 664px;

          .date {
            @include date;
          }

          .section_text {
            @include mediumText;
            color: $colorTextSecondary;

          }
        }

        .image-wrapper {
          width: 100%;
          align-items: center;
          justify-content: center;
        }
        @media (max-width: 991px) {
          flex-direction: column-reverse;
        }
      }
    }
  }

}

.benefits-promotions-section-view {
  margin: 20px 0 80px;

  .page_container_small {
    max-width: 584px;
    margin: 0 auto;

    .view-wrapper {
      .date {
        @include date;
        background: $colorBackgroundSurfaceSecondary;
      }

      .image-wrapper {
        margin: 24px auto;
        text-align: center;
      }

      .section_text {
        @include mediumText;
        color: $colorTextPrimary;
        margin-top: 16px;
      }

      .join-wrapper {
        padding: 40px 0;
        position: relative;
        margin-bottom: 40px;

        .join {
          padding: 24px;
          background: $colorBackgroundSurfaceSecondary;
          border-radius: $borderRadius16;

          .title {
            color: $colorTextPrimary;
            margin-bottom: 16px;
            @include subtitleSize;
            font-family: "NotoSansArmenian-SemiBold", sans-serif;

          }

          .text {
            @include mediumText;
            color: $colorTextPrimary;
            margin-bottom: 20px;
          }
        }

        &:after {
          position: absolute;
          bottom: 0;
          left: 0;
          content: '';
          width: 100%;
          height: 1px;
          background: $colorBackgroundSurfaceStroke;
        }
      }
    }
  }
}

.mobile-filter-modal{
  .select__control .select__value-container .select__single-value {
    @include mediumText;
    color: $colorTextPrimary;

  }
}

.gifts-sk{
  li{
    height: 200px;
    width: 30% !important;
    &:last-child,&:nth-last-child(2){
      width: 48% !important;
    }
    @media (max-width: 991px) {
      width: 100% !important;
      &:last-child,&:nth-last-child(2){
        width: 100% !important;
      }
    }
  }
}
.products-sk{
  li{
    height: 500px;
    width: 31.3% !important;
    @media (max-width: 991px) {
      width: 45% !important;
      margin: 0 auto;
    }
    @media (max-width: 678px) {
      width: 90%!important;
      margin: 0 auto;
      &:last-child{
        display: none;
      }
    }
  }
}
.banner-sk{
  height: 400px;
}
.text-sk{
  li{

    height: 10vw;
    &:first-child{
      height: 5vw;
    }
    @media (max-width: 991px) {
      width: 90%;
      margin: 0 auto;
    }
  }
}